import React,{useEffect, useState, useRef} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
// import {Table} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import { Funnel } from 'react-bootstrap-icons';
import slugify from 'react-slugify';

const Promotions = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token') 
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState(-1);
    
    useEffect(() => {
        getPromoCodes();
    }, [sortBy, searchQuery])
    
    const [modalTitle, setModalTitle] = useState('Add PromoCode');
    const [lgshow, setShow] = useState(false);

    const [promoCodeId, setPromoCodeId] = useState(0);
    const [code, setCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [discountType, setDiscountType] = useState(1);
    const [from, setFrom] = useState('');
    const [expiry, setExpiry] = useState('');
    const [used, setUsed] = useState(0);
    const [status, setStatus] = useState(1);
    const [count, setCount] = useState(0);

    const [validated, setValidated] = useState(false);
    const [promoCodes, setPromoCodes] = useState([]);

    const [viewMode, setViewMode] = useState(false);

    const getPromoCodes = async () => {
        axios.get(`${process.env.REACT_APP_API_URL}/promocode/all?keyword=${searchQuery}&sort=${sortBy}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            // console.log(res.data.total);    
                if(res.data.total > 0){
                    setPromoCodes(res.data.promocode);
                    setCount(res.data.total);
                }else{
                    setPromoCodes([]);
                    setCount(0);
                }
            }
        ).catch(err => {
            console.log(err);
        })
    }


    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if(form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        if(form.checkValidity() === true){
            e.preventDefault();
            if(code === '' || discount === 0 || discountType === 0 || from === '' || expiry === '' ){
                return toast.error("Kindly fill all the required fields");
            }

            if(from > expiry){
                return toast.error("From date cannot be greater than expiry date");
            }
            
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${btn.innerHTML}`;
            btn.disabled = true;

            if(promoCodeId !== 0){
                const data = {
                    promoCodeId: promoCodeId,
                    code: code,
                    discount: discount,
                    discount_type: discountType,
                    from: from,
                    expiry: expiry,
                    status: status
                }
                updatePromoCode(data);
            
            }else{
                const data = {
                    code: code,
                    discount: discount,
                    discount_type: discountType,
                    from: from,
                    expiry: expiry,
                    status: status
                }
                addPromoCode(data);
            }
        }
    }

    const addPromoCode = async (data) => {
        axios.post(`${process.env.REACT_APP_API_URL}/promocode`, data ,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                if(res.data.status === true){
                    toast.success('Promocode added successfully');
                    handleClose();
                    getPromoCodes();
                }else{
                    toast.error(res.data.message);
                }

            }
        ).catch(err => {
            console.log(err);
            toast.error(err.response.data.message);
        })
        .finally(() => {
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = modalTitle;
            btn.disabled = false;
        })
    }

    const updatePromoCode = async (data) => {
        axios.patch(`${process.env.REACT_APP_API_URL}/promocode/${data.promoCodeId}`, data,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                if(res.data.status === true){
                    toast.success('Promocode updated successfully');
                    handleClose();
                    getPromoCodes();
                }else{
                    toast.error(res.data.message);
                }
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
    }
    

    const handleShow = (e, title="Add Promo", button="Add Promo", viewMode=false) => {
        if(viewMode){
            setViewMode(true);
        }else{
            setViewMode(false);
        }
        setModalTitle(title);
        setModalTitle(button);
        setShow(true);
    }

    const handleClose = () => {
        setPromoCodeId(0);
        setCode('');
        setDiscount(0);
        setDiscountType(1);
        setFrom('');
        setExpiry('');
        setStatus(1);
        setShow(false);
        setValidated(false);
    }

    const viewDetails = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/promocode/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setPromoCodeId(res.data.promocode._id);
            setCode(res.data.promocode.code);
            setDiscount(res.data.promocode.discount);
            setDiscountType(res.data.promocode.discount_type);
            setFrom(res.data.promocode.from);
            setExpiry(res.data.promocode.expiry);
            setStatus(res.data.promocode.status);

            handleShow("", 'View Details', 'View Details', true);
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const editTest = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/promocode/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setPromoCodeId(res.data.promocode._id);
            setCode(res.data.promocode.code);
            setDiscount(res.data.promocode.discount);
            setDiscountType(res.data.promocode.discount_type);
            var from_date = new Date(res.data.promocode.from);
            var expiry_date = new Date(res.data.promocode.expiry);
            // delete T from date
            from_date = from_date.toISOString().split('T')[0];
            expiry_date = expiry_date.toISOString().split('T')[0];
            // console.log(from_date, expiry_date);
            
            setFrom(from_date);
            setExpiry(expiry_date);
            setStatus(res.data.promocode.status);
            handleShow("", 'Edit Promo', 'Update Promo');
            
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const deleteTest = async (id) => {
        // confirm
        const confirm = window.confirm('Are you sure you want to delete this promotion?');
        if(confirm){
            axios.delete(`${process.env.REACT_APP_API_URL}/promocode/${id}`,{ headers: {"Authorization": ` ${token}`}})
            .then(res => {
                toast.success('Promocode deleted successfully');
                getPromoCodes();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchQuery(e.target[0].value);
    }


    const fromDateWithOutTime = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = d.getMonth()+1;
        const monthName = d.toLocaleString('default', { month: 'short' });
        const day = d.getDate();
        const formattedDate = `${monthName} ${day}, ${year}`;

        return formattedDate;
    }
        

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Promotions</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Promotions <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{count<10?'0'+count:count}</span></span>
                    
                    </h3>
                    <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add new promo code'
                    >
                    <button
                        onClick={e=>handleShow(e, "Add Promo", "Add Promo")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        New Promotion
                    </button>
                    </div>
                </div>
                <div className='card-header border-0 pt-2'>
                    <div className='card-toolbar'>
                        <div className='input-group input-group-sm input-group-solid w-230px '>
                            <div className='dropdown dropdown-inline'>
                                <button
                                type='button'
                                className='btn btn-sm btn-light-primary py-4'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                                >
                                    <Funnel size={18}
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='svg-icon-2 svg-icon-gray-500'
                                    />
                                </button>
                                
                                <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
                                    {/*begin::Navigation*/}
                                    <ul className='navi navi-hover' style={{listStyle:"none"}}>
                                        <li className={`navi-item mb-2 pt-2 ${sortBy ===1? 'text-primary':''}` } onClick={e=>setSortBy(1)}>
                                            <span className='navi-text fw-bold' style={{cursor:"pointer"}}>Ascending</span>
                                        </li>
                                        <li className={`navi-item ${sortBy ===-1? 'text-primary':''}` } onClick={e=>setSortBy(-1)}>
                                            <span className='navi-text fw-bold' style={{cursor:"pointer"}}>Descending</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <span className='input-group-text ms-2'>
                                <KTSVG
                                path='/media/icons/duotune/general/gen004.svg'
                                className='svg-icon-2 svg-icon-gray-900 '
                                />
                            </span> 
                            <form className='d-flex align-items-center' onSubmit={handleSearch}>
                                <input
                                type='text'
                                className='form-control ps-2 '
                                placeholder='Search'
                                value={searchQuery}
                                onChange={e=>setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Promo Code</th>
                                    <th className='min-w-150px'>Discount</th>
                                    {/* <th className='min-w-150px'>Discount Type</th> */}
                                    <th className='min-w-150px'>From Date</th>
                                    <th className='min-w-150px'>Expiry</th>
                                    <th className='min-w-120px bg-light text-center'>Used</th>
                                    <th className='min-w-50px'>Status</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {promoCodes.map((promocode, index) => {
                                    if(promocode.status === 1){
                                        var promocode_status = 'Active'
                                        var statusColor = 'success'
                                    }else{
                                        var promocode_status = 'Inactive'  
                                        var statusColor = 'danger' 
                                    }
                                    return (

                                    
                                <tr key={index} id={`row`+promocode._id}>
                                    <td>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'> */}
                                                    {promocode.code}
                                                {/* </a> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {promocode.discount} {promocode.discount_type === '1' ? '%' : '$'}
                                    </td>
                                    <td>
                                        {fromDateWithOutTime(promocode.from)}
                                    </td>
                                    <td>
                                        {fromDateWithOutTime(promocode.expiry)}
                                    </td>
                                    <td className='bg-light text-center'>
                                        {promocode.used}
                                    </td>
                                    <td className=''>
                                        <span className={`badge badge-light-`+statusColor}>{promocode_status}</span>
                                    </td>
                                    <td className=''>
                                        <div className='d-flex flex-shrink-0'>
                                            {/* <button onClick={() => viewDetails(promocode._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen028.svg'
                                                className='svg-icon-3'
                                            />
                                            </button> */}
                                            <button onClick={() => editTest(promocode._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                            </button>
                                            <button onClick={() => deleteTest(promocode._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                            />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                    )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        {/* ====================================== */}
                        {/* Modal */}
        {/* ====================================== */}
            
        <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
            {/* <Modal.Header closeButton >
            <Modal.Title className='h5'>{modalTitle}</Modal.Title>
            </Modal.Header> */}
            <div className='modal-header pb-0 border-0 justify-content-end'>
                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
            </div>
            <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                <div className='text-center mb-13'>
                    <h1 className='mb-3'>{modalTitle}</h1>
                </div>

                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                    <Form.Group className='mt-3' controlId="Promocode">
                        <Form.Label>Promo Code <span className='text-danger'>*</span></Form.Label>
                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Promo Code" value={code} onChange={(e)=> setCode(e.target.value)} required disabled={viewMode}/>
                    </Form.Group>
                    <Form.Group className='mt-3' controlId="Discount">
                        <Form.Label>Discount <span className='text-danger'>*</span></Form.Label>
                        <Form.Control className='form-control-sm' type="number" placeholder="Enter Discount" value={discount} onChange={(e)=> setDiscount(e.target.value)} required disabled={viewMode}/>
                    </Form.Group>
                    <Form.Group className='mt-3' controlId="DiscountType">
                        <Form.Label>Discount Type <span className='text-danger'>*</span></Form.Label>
                        <Form.Select aria-label="Default select example" className='form-select-sm' value={discountType} onChange={(e)=> setDiscountType(e.target.value)} disabled={viewMode}>
                            <option value=''>Select Discount Type </option>
                            <option value='1'>Percentage</option>
                            <option value='2'>Amount</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-3' controlId="Expiry">
                        <Form.Label>From <span className='text-danger'>*</span></Form.Label>
                        <Form.Control className='form-control-sm' type="date" placeholder="Enter From Date" value={from} onChange={(e)=> setFrom(e.target.value)} required disabled={viewMode}/>
                    </Form.Group>
                    <Form.Group className='mt-3' controlId="Expiry">
                        <Form.Label>Expiry <span className='text-danger'>*</span></Form.Label>
                        <Form.Control className='form-control-sm' type="date" placeholder="Enter Expiry" value={expiry} onChange={(e)=> setExpiry(e.target.value)} required disabled={viewMode}/>
                    </Form.Group>

                    <Form.Group className='mt-3' controlId="formBasicStatus">
                        <Form.Label>Status</Form.Label>
                        <Form.Select aria-label="Default select example" className='form-select-sm' value={status} onChange={(e)=> setStatus(e.target.value)} disabled={viewMode}>
                            <option value='1'>Active</option>
                            <option value='0'>Inactive</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
                {/* modal footer */}
                {viewMode ? '': (
                    <div className='modal-footer d-flex justify-content-center pt-4'>
                        <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                            Close
                        </button>
                        <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" onClick={handleSubmit}>
                            {modalTitle}
                        </button>
                    </div>
                )}
            </Modal>
        </>
    )
}

export default Promotions
import React,{useEffect, useState, useRef} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {Table} from 'react-bootstrap'
import {KTSVG} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'


const Categories = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token') //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2FhOTkyYTM3NmI4Y2UxOWYwMzE5NmQiLCJpYXQiOjE2NzIxMjQ3NDl9.GVST69iPilsjJgfTp9os8Gr-9KyKEf4c5LvbleQss74'
    
    useEffect(() => {
        getCategories();
    }, [])
    
    const [modalTitle, setModalTitle] = useState('Add Category');
    const [lgshow, setShow] = useState(false);
    const editorRefDescription = useRef(null);


    const [category_id, setCategoryId] = useState(0);
    const [name, setName] = useState("");
    const [category_status, setCategoryStatus] = useState(1);
    const [description, setDescription] = useState("");
    const [DescriptionInitial, setDescriptionInitial] = useState("");
    const [category_logo, setCategoryLogo] = useState();
    const [category_logo_selected, setCategortLogoSelected] = useState("");
    const [category_thumbnail, setCategoryThumbnail] = useState();
    const [category_thumbnail_selected, setCategoryThumbnailSelected] = useState("");
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [count, setCount] = useState(0);
    const [validated, setValidated] = useState(false);
    const [fileSizeErrorPhoto , setFileSizeErrorPhoto] = useState(false);
    const [fileSizeErrorThumbnail , setFileSizeErrorThumbnail] = useState(false);
    const [fileFormatError, setFileFormatError] = useState(false);
    
    const [fileFormatErrorThumbnail, setFileFormatErrorThumbnail] = useState(false);
    const [isEditPhoto, setIsEditPhoto] = useState(false);
    const [isEditThumbnail, setIsEditThumbnail] = useState(false);
    const [categories, setCategories] = useState([]);

    const [viewMode, setViewMode] = useState(false);

    const getCategories = async () => {
        axios.get(`${process.env.REACT_APP_API_URL}/categories`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
                if(res.data.total > 0){
                    setCategories(res.data.categories);
                    setCount(res.data.total);
                }
            }
        ).catch(err => {
            console.log(err);
        })
    }


    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if(form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
            
        }
        setValidated(true);
        if(form.checkValidity() === true){
            e.preventDefault();
            if(name ==="" || description ===""){
                return toast.error("Kindly fill all the required fields");
            }
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${btn.innerHTML}`;
            btn.disabled = true;

            if(category_id !== 0){
                const data = {
                    category_id: category_id,
                    name: name,
                    category_status: category_status,
                    description: description,
                    meta_title:metaTitle,
                    meta_description: metaDescription,
                }
                updateCategory(data);
            
            }else{
                const data = {
                    name: name,
                    category_status: category_status,
                    description: description,
                    meta_title:metaTitle,
                    meta_description: metaDescription,
                }
                addCategory(data);
            }
        }
    }

    const addCategory = async (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('status', data.category_status);
        formData.append('description', data.description);
        formData.append('meta_title', data.meta_title);
        formData.append('meta_description', data.meta_description);
        formData.append('logo', category_logo_selected);
        formData.append('thumbnail', category_thumbnail_selected);


        axios.post(`${process.env.REACT_APP_API_URL}/categories`, formData,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                if(res.data.status === true){
                    toast.success('Category added successfully');
                    handleClose();
                    getCategories();
                }else{
                    toast.error('Category not added');
                }
                
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        }
        )
        .finally(() => {
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = modalTitle;
            btn.disabled = false;
        })
    }

    const updateCategory = async (data) => {
        const fileName = category_logo.split('/').pop();
        const fileNameThumbnail = category_thumbnail.split('/').pop();
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('status', data.category_status);
        formData.append('description', data.description);
        formData.append('meta_title', data.meta_title);
        formData.append('meta_description', data.meta_description);

        if(category_logo_selected.name != undefined && fileName != category_logo_selected.name){
            formData.append('logo', category_logo_selected);
        }
        if(category_thumbnail_selected.name != undefined && fileNameThumbnail != category_thumbnail_selected.name){
            formData.append('thumbnail', category_thumbnail_selected);
        }


        axios.patch(`${process.env.REACT_APP_API_URL}/categories/${data.category_id}`, formData, { headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                if(res.data.status === true){
                    toast.success('Category updated successfully');
                    handleClose();
                    getCategories();
                }else{
                    toast.error('Category not updated');
                }
                
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
    }
    
    const handlePhoto = (e) => {
        setFileSizeErrorPhoto(false);
        setFileFormatError(false);

        if(e.target.files[0].size > 300000){
            setFileSizeErrorPhoto(true);
            e.target.value = null;
            return;
        }else{
            if(e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/svg' && e.target.files[0].type !== 'image/svg+xml'  && e.target.files[0].type !== 'image/webp'){
                // toast.error('File format not supported');
                setFileFormatError(true);
                e.target.value = null;
                return;
            }else{
                setCategortLogoSelected(e.target.files[0]);
                setIsEditPhoto(false)
            }
        }
    }

    const handleThumbnail = (e) => {
        setFileSizeErrorThumbnail(false);
        setFileFormatErrorThumbnail(false);

        if(e.target.files[0].size > 300000){
            setFileSizeErrorThumbnail(true);
            e.target.value = null;
            return;
        }else{
            if(e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/svg' && e.target.files[0].type !== 'image/svg+xml'  && e.target.files[0].type !== 'image/webp'){
                // toast.error('File format not supported');
                setFileFormatErrorThumbnail(true);
                e.target.value = null;
                return;
            }else{
                setCategoryThumbnailSelected(e.target.files[0]);
                setIsEditThumbnail(false)
            }
        }
    }


    const handleShow = (e, title="Add Category", button="Add Category", viewMode=false) => {
            if(viewMode){
                setViewMode(true);
            }else{
                setViewMode(false);
            }
        
        // console.log(title, button);
            setModalTitle(title);
            setModalTitle(button);
            setShow(true);
    }

    const handleClose = () => {
        setValidated(false);
        setCategoryId(0);
        setName("");
        setCategoryStatus(1);
        setCategoryLogo("");
        setCategoryThumbnail("");
        setDescription("");
        setDescriptionInitial("");
        setMetaTitle("");
        setMetaDescription("");
        setIsEditPhoto(false);
        setShow(false);

    }

    const viewDetails = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/categories/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setCategoryId(res.data._id);
            setName(res.data.name);
            setCategoryStatus(res.data.status);
            setDescription(res.data.description);
            setMetaTitle(res.data.meta_title);
            setMetaDescription(res.data.meta_description);
            setDescriptionInitial(res.data.description);
            setCategortLogoSelected(res.data.logo);
            setCategoryLogo(res.data.logo);
            setCategoryThumbnailSelected(res.data.thumbnail);
            setCategoryThumbnail(res.data.thumbnail);
            setIsEditPhoto(true);

            handleShow("", 'View Details', 'View Details', true);
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const editCategory = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/categories/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setCategoryId(res.data._id);
            setName(res.data.name);
            setCategoryStatus(res.data.status);
            setDescription(res.data.description);
            setDescriptionInitial(res.data.description);
            setMetaTitle(res.data.meta_title);
            setMetaDescription(res.data.meta_description);
            setCategortLogoSelected(res.data.logo);
            setCategoryLogo(res.data.logo);
            setCategoryThumbnailSelected(res.data.thumbnail);
            setCategoryThumbnail(res.data.thumbnail);
            setIsEditPhoto(true);
            setIsEditThumbnail(true);
            
            handleShow("", 'Edit Category', 'Update Category');
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const deleteCategory = async (id) => {
        // confirm
        const confirm = window.confirm('Are you sure you want to delete this category?');
        if(confirm){
            axios.delete(`${process.env.REACT_APP_API_URL}/categories/${id}`,{ headers: {"Authorization": ` ${token}`}})
            .then(res => {
                toast.success('Category deleted successfully');
                getCategories();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
        }
    }

        

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Categories</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Categories <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{count<10?'0'+count:count}</span></span>
                    
                    </h3>
                    
                    <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                    >
                    <button
                        onClick={e=>handleShow(e, "Add Category", "Add Category")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        New Category
                    </button>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Test Name</th>
                                    {/* <th className='min-w-140px'>Parent Category</th> */}
                                    <th>Description</th>
                                    <th className='min-w-50px'>Status</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((category, index) => {
                                    if(category.status === 1){
                                        var category_status_name = 'Active'
                                        var status = 'success'
                                    }else{
                                        var category_status_name = 'Inactive'  
                                        var status = 'danger' 
                                    }
                                    return (

                                    
                                <tr key={index} id={`row`+category._id}>
                                    <td>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'> */}
                                                    {category.name}
                                                {/* </a> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {/* {category.description} */}
                                        {/* substr */}
                                        {category.description.length > 150 ? category.description.substring(0, 150) + '...' : category.description}
                                    </td>
                                    <td className=''>
                                        <span className={`badge badge-light-`+status}>{category_status_name}</span>
                                    </td>
                                    <td className=''>
                                        <div className='d-flex flex-shrink-0'>
                                            <button onClick={() => viewDetails(category._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen028.svg'
                                                className='svg-icon-3'
                                            />
                                            </button>
                                            <button onClick={() => editCategory(category._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                            </button>
                                            <button onClick={() => deleteCategory(category._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                            />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                    )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
                <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                    {/* <Modal.Header closeButton >
                    <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                    </Modal.Header> */}
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>{modalTitle}</h1>
                        </div>

                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Form.Group controlId="CategoryName">
                                <Form.Label>Category Name <span className='text-danger'>*</span></Form.Label>
                                <Form.Control className='form-control-sm' type="test" placeholder="Enter Category Name" value={name} onChange={(e)=> setName(e.target.value)} required disabled={viewMode}/>
                                {/* <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text> */}
                            </Form.Group>
                            
                            {/* photo */}
                            
                            <Form.Group controlId="formBasicPhoto" className='mt-5 mb-2'>
                                <Form.Label>Logo <span className='text-danger'>*</span></Form.Label>
                                <Form.Text className="text-muted">
                                    {fileSizeErrorPhoto && <div className='text-danger'>(File size must be less than 300kb)</div>}
                                    {fileFormatError && <div className='text-danger'>(File must be jpg / png / svg)</div>}
                                </Form.Text>
                                {viewMode == true ? '' :
                                    <Form.Control className='form-control-sm' type="file" placeholder="Enter Category Name" onChange={handlePhoto} />
                                }
                            </Form.Group>
                            {
                                isEditPhoto?(
                                    <img src={category_logo_selected} alt="" width="100px" height="100px" className='mb-3' />
                                ):(
                                    ''
                                )
                            }

                            <Form.Group controlId="formBasicPhoto" className='mt-5 mb-2'>
                                <Form.Label>Thumbnail <span className='text-danger'>*</span></Form.Label>
                                <Form.Text className="text-muted">
                                    {fileSizeErrorThumbnail && <div className='text-danger'>(File size must be less than 300kb)</div>}
                                    {fileFormatErrorThumbnail && <div className='text-danger'>(File must be jpg / png / svg)</div>}
                                </Form.Text>
                                {viewMode == true ? '' :
                                    <Form.Control className='form-control-sm' type="file" placeholder="Enter Category Name" onChange={handleThumbnail} />
                                }
                            </Form.Group>
                            {
                                isEditThumbnail?(
                                    <img src={category_thumbnail_selected} alt="" width="100px" height="100px" className='mb-3' />
                                ):(
                                    ''
                                )
                            }

                                <div className='row'>
                                    <div className='col-12'>
                                        
                                        <Form.Group controlId="formBasicStatus" className='mt-5'>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Select aria-label="Default select example" className='form-select-sm' value={category_status} onChange={(e)=> setCategoryStatus(e.target.value)} disabled={viewMode}>
                                                <option value='1'>Active</option>
                                                <option value='0'>Inactive</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>

                            {/* form.group for meta_title and meta_description */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicMetaTitle" className='mt-5'>
                                        <Form.Label>Meta Title</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Meta Title" value={metaTitle} onChange={(e)=> setMetaTitle(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicMetaDescription" className='mt-5'>
                                        <Form.Label>Meta Description</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Meta Description" value={metaDescription} onChange={(e)=> setMetaDescription(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>
                            <Form.Group controlId="formBasicShortDescription" className='mt-5'>
                                <Form.Label>Description <span className='text-danger'>*</span></Form.Label>
                                {/* <Form.Control as="textarea" rows={3} value={description} onChange={(e)=> setDescription(e.target.value)} required disabled={viewMode}/> */}
                                <Editor
                                    disabled={viewMode}
                                    apiKey='3djck8249ppha7ajlw5u4i4sslhhbefzwbhbhys4v3fy1t1q'
                                    onInit={(evt, editor) => editorRefDescription.current = editor}
                                    initialValue={DescriptionInitial}
                                    init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help | table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    onEditorChange={(newText) => setDescription(newText)}
                                    
                                    // onChange={(e) => setDescription(e.target.getContent())}
                                />
                            </Form.Group>
                            
                        </Form>
                    </Modal.Body>
                        {/* modal footer */}
                        {viewMode ? '': (
                            <div className='modal-footer d-flex justify-content-center pt-4'>
                                <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                    Close
                                </button>
                                <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" onClick={handleSubmit}>
                                    {modalTitle}
                                </button>
                            </div>
                        )}
                    </Modal>

            {/* </Modal> */}
            {/* </div> */}

        </>
    )
}

export default Categories
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import { Link } from 'react-router-dom'
import CanvasJSReact from '@canvasjs/react-charts';
import moment from 'moment' 

var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const DashboardPage = (props) => (
    <>
    {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5'>
            <div className="row gy-5 g-xl-10">
                <div className="col-xl-4">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex flex-column flex-grow-1">
                                <Link to="/orders" className="text-dark text-hover-primary fw-bolder fs-3 mb-4">Total Orders</Link>
                                
                                <div className="mt-5">
                                    <span className="text-dark fw-bolder fs-3x me-2 lh-0">{props.stats?.totalOrders}</span>
                                    {props.stats?.totalOrdersThisMonth > 0 && (
                                    <span className="fw-bolder fs-6 lh-0 text-primary">+ {props.stats?.totalOrdersThisMonth} this month</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex flex-column flex-grow-1">
                                <Link to="/tests" className="text-dark text-hover-primary fw-bolder fs-3 mb-4">Total Vouchers</Link>
                                
                                <div className="mt-5">
                                    <span className="text-dark fw-bolder fs-3x me-2 lh-0">{props.stats?.totalTests}</span>
                                    {/* <span className="text-dark fw-bolder fs-6 lh-0">+ 28 this week</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-4">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex flex-column flex-grow-1">
                                <Link to="/tests" className="text-dark text-hover-primary fw-bolder fs-3 mb-4">Total Tests</Link>
                                
                                    <div className="mt-5">
                                        <span className="text-dark fw-bolder fs-3x me-2 lh-0">{props.stats?.totalTests}</span>
                                    
                                    </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-xl-4">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex flex-column flex-grow-1">
                                <Link to="/categories" className="text-dark text-hover-primary fw-bolder fs-3 mb-4">Total Categories</Link>
                                
                                <div className="mt-5">
                                    <span className="text-dark fw-bolder fs-3x me-2 lh-0">{props.stats?.totalCategories}</span>
                                    {/* <span className="text-dark fw-bolder fs-6 lh-0">+ 28 this week</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

const GraphsComponents = (props) => {
    // loop through the props.stats.monthlyWiseOrders and get the month and count
    const dataPoints = props.stats?.monthlyWiseOrders?.map((item) => {
        return {
            //month name from month number and year
            label: moment(item._id.month, 'M').format('MMMM') + ' ' + item._id.year,
            y: item.count
        }
    }
    )
    console.log(dataPoints);

    
    const options  = {
        title: {
          text: "Monthly Wise Chart for Sales"
        },
        data: [
          {
            type: "column",
            dataPoints: dataPoints
          }
        ]
    }

    return(
        <>
            <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                <div className="col-xl-12">
                    <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                        <div className="card-body d-flex flex-column">
                        <CanvasJSChart options = {options}
                            /* onRef = {ref => this.chart = ref} */
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// const SalesReportChart = (props) => {
//     // complete with cancel order
    

//     const CancelOrdersDataPoints = props.stats?.monthlyWiseCancelledOrders?.map((item) => {
//         return {
//             //month name from month number and year
//             label: moment(item._id.month, 'M').format('MMMM') + ' ' + item._id.year,
//             y: item.count
//         }
//     })

//     const SuccessOrdersDataPoints = props.stats?.monthlyWiseSuccessfulOrders?.map((item) => {
//         return {
//             //month name from month number and year
//             label: moment(item._id.month, 'M').format('MMMM') + ' ' + item._id.year,
//             y: item.count
//         }

//     })

    
//     const options  = {
//         title: {
//           text: "Monthly Wise Chart for Sales"
//         },
//         axisX: {
//             title: "States"
//         },
//         axisY: {
//             title: "Successful Orders - Units",
//             titleFontColor: "#4F81BC",
//             lineColor: "#4F81BC",
//             labelFontColor: "#4F81BC",
//             tickColor: "#4F81BC",
//             includeZero: true
//         },
//         axisY2: {
//             title: "Cancelled Orders - Units",
//             titleFontColor: "#C0504E",
//             lineColor: "#C0504E",
//             labelFontColor: "#C0504E",
//             tickColor: "#C0504E",
//             includeZero: true
//         },
//         toolTip: {
//             shared: true
//         },
//         data: [
//           {
//             type: "column",
//             name: "Successful Orders",
//             showInLegend: true,      
// 		    yValueFormatString: "#,##0.# Units",
//             dataPoints: SuccessOrdersDataPoints
//           },{
//             type: "column",
//             name: "Cancelled Orders",
//             showInLegend: true,
//             yValueFormatString: "#,##0.# Units",
//             dataPoints: CancelOrdersDataPoints
//           }
//         ]
//     }

//     return(
//         <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
//             <div className="col-xl-12">
//                 <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
//                     <div className="card-body d-flex flex-column">
//                     <CanvasJSChart options = {options}
//                         /* onRef = {ref => this.chart = ref} */
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

const DashboardWrapper = () => {
  const intl = useIntl()
  const [stats, setStats] = useState({})
    useEffect(() => {
        const token =  localStorage.getItem('kt-auth-react-v.api_token')
        axios.get(`${process.env.REACT_APP_API_URL}/dashboard/dashboard-stats`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                setStats(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage stats={stats} />
      <GraphsComponents stats={stats} />
      {/* <SalesReportChart stats={stats} /> */}
      
    </>
  )
}

export {DashboardWrapper}

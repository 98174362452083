import React,{useEffect, useState, useRef} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
// import {Table} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import { Funnel } from 'react-bootstrap-icons';
import slugify from 'react-slugify';

const Tests = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token') //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2FhOTkyYTM3NmI4Y2UxOWYwMzE5NmQiLCJpYXQiOjE2NzIxMjQ3NDl9.GVST69iPilsjJgfTp9os8Gr-9KyKEf4c5LvbleQss74'
    const [selectCategory, setSelectCategory] = useState("");
    const [searchQuery, setSearchQuery] = useState('');

    
    useEffect(() => {
        getTests();
    }, [selectCategory, searchQuery])
    
    const [modalTitle, setModalTitle] = useState('Add Voucher');
    const [lgshow, setShow] = useState(false);

    const editorRefDescription = useRef(null);
    // const editorRefLong2 = useRef(null);
    const [description, setDescription] = useState('');
    const [DescriptionInitial, setDescriptionInitial] = useState("");


    const [test_id, setTestId] = useState(0);
    const [test_name, setTestName] = useState("");
    const [parent_category, setParentCategory] = useState("");
    const [status, setTestStatus] = useState(1);
    const [validity, setValidity] = useState('');
    const [price, setPrice] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [count, setCount] = useState(0);
    const [isPopular, setIsPopular] = useState(0);
    const [slug, setSlug] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [productSchema, setProductSchema] = useState('');
    const [validated, setValidated] = useState(false);

    const [tests, setTests] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);

    const [viewMode, setViewMode] = useState(false);

    const getTests = async () => {
        axios.get(`${process.env.REACT_APP_API_URL}/test?category=${selectCategory}&keyword=${searchQuery}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            // console.log(res.data.total);    
            if(res.data.total > 0){
                    setTests(res.data.tests);
                    setCount(res.data.total);
                }
                    setCategoryNames(res.data.categories);
                    // console.log(res.data.categories);

            }
        ).catch(err => {
            console.log(err);
        })
    }


    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if(form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        if(form.checkValidity() === true){
            e.preventDefault();
            if(test_name === "" || slug === "" || price === "" || description ==="" ){
                return toast.error("Kindly fill all the required fields");
            }
            
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${btn.innerHTML}`;
            btn.disabled = true;
            
            console.log(price, discountedPrice);
            
            if(test_id !== 0){
                if(parseInt(price) > parseInt(discountedPrice)){
                    btn.disabled = false;
                    return toast.error("Discounted price cannot be greater than actual price");
    
                }
                
                const data = {
                    test_id: test_id,
                    name: test_name,
                    parent_category: parent_category,
                    price: price,
                    actual_price: discountedPrice,
                    status: status,
                    description: description,
                    slug: slug,
                    meta_title:metaTitle,
                    meta_description: metaDescription,
                    product_schema: productSchema,
                    popular: isPopular,
                    expiration: validity
                }
                updateTest(data);
            
            }else{
                
                if(price > discountedPrice){
                    btn.disabled = false;
                    return toast.error("Discounted price cannot be greater than actual price");
    
                }
                const data = {
                    name: test_name,
                    parent_category: parent_category,
                    price: price,
                    actual_price: discountedPrice,
                    status: status,
                    description: description,
                    slug: slug,
                    meta_title:metaTitle,
                    meta_description: metaDescription,
                    product_schema: productSchema,
                    popular: isPopular,
                    expiration: validity
                }
                addTest(data);
            }
        }
    }

    const addTest = async (data) => {
        axios.post(`${process.env.REACT_APP_API_URL}/test`, data ,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                if(res.data.status === true){
                    toast.success('Voucher added successfully');
                    handleClose();
                    getTests();
                }else{
                    toast.error(res.data.message);
                }

            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        }
        )
        .finally(() => {
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = modalTitle;
            btn.disabled = false;
        })
    }

    const updateTest = async (data) => {

        axios.put(`${process.env.REACT_APP_API_URL}/test/${data.test_id}`, data,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                if(res.data.status === true){
                    toast.success('Voucher updated successfully');
                    handleClose();
                    getTests();
                }else{
                    toast.error(res.data.message);
                }
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
    }
    

    const handleShow = (e, title="Add Voucher", button="Add Voucher", viewMode=false) => {
            if(viewMode){
                setViewMode(true);
            }else{
                setViewMode(false);
            }
        
        // console.log(title, button);
            setModalTitle(title);
            setModalTitle(button);
            setShow(true);
    }

    const handleClose = () => {
        setValidated(false);
        setTestId(0);
        setTestName("");
        setParentCategory("");
        setTestStatus(1);
        setDescription("");
        setDescriptionInitial("");
        setValidity("");
        setIsPopular(0);
        setPrice(0);
        setSlug("");
        setMetaTitle("");
        setMetaDescription("");
        setProductSchema("");
        setDiscountedPrice(0);
        setShow(false);

    }

    const viewDetails = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/test/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setTestId(res.data._id);
            setTestName(res.data.name);
            setParentCategory(res.data.parent_category);
            setTestStatus(res.data.status);
            
            setDescription(res.data.description);
            setPrice(res.data.price);
            setDiscountedPrice(res.data.actual_price);
            setIsPopular(res.data.popular);
            setSlug(res.data.slug);
            setMetaTitle(res.data.meta_title);
            setMetaDescription(res.data.meta_description);
            setValidity(res.data.expiration);
            setDescriptionInitial(res.data.description);
            setProductSchema(res.data.product_schema);

            handleShow("", 'View Details', 'View Details', true);
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const editTest = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/test/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setTestId(res.data._id);
            setTestName(res.data.name);
            setParentCategory(res.data.parent_category);
            setTestStatus(res.data.status);
            
            setPrice(res.data.price);
            setDiscountedPrice(res.data.actual_price);
            setIsPopular(res.data.popular);
            setSlug(res.data.slug);
            setMetaTitle(res.data.meta_title);
            setMetaDescription(res.data.meta_description);
            setValidity(res.data.expiration);
            setDescription(res.data.description);
            setDescriptionInitial(res.data.description);
            setProductSchema(res.data.product_schema);
            
            handleShow("", 'Edit Voucher', 'Update Voucher');
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const deleteTest = async (id) => {
        // confirm
        const confirm = window.confirm('Are you sure you want to delete this test?');
        if(confirm){
            axios.delete(`${process.env.REACT_APP_API_URL}/test/${id}`,{ headers: {"Authorization": ` ${token}`}})
            .then(res => {
                toast.success('Voucher deleted successfully');
                getTests();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        // console.log();
        setSearchQuery(e.target[0].value);
    }

    const generateSlug = (e) => {
        // slug on test name
        if(test_name === ""){
            setSlug("");
            toast.error('Please enter test name');
            return;
        }
        const slug = slugify(test_name, {
            replacement: '-',  // replace spaces with replacement character, defaults to `-`
            remove: undefined, // remove characters that match regex, defaults to `undefined`
            lower: true,      // convert to lower case, defaults to `false`
            strict: false,     // strip special characters except replacement, defaults to `false`
            locale: 'vi'       // language code of the locale to use
            })
        setSlug(slug);
    }
        

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Vouchers</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Vouchers <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{count<10?'0'+count:count}</span></span>
                    
                    </h3>
                    <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                    >
                        
                        
                    <button
                        onClick={e=>handleShow(e, "Add Voucher", "Add Voucher")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        New Voucher
                    </button>
                    </div>
                </div>
                <div className='card-header border-0 pt-2'>
                    <div className='card-toolbar'>
                        
                        <div className='input-group input-group-sm input-group-solid w-230px '>
                        <button
                            type='button'
                            className='btn btn-sm btn-light-primary me-3'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            >
                                <Funnel size={18}
                                path='/media/icons/duotune/arrows/arr072.svg'
                                className='svg-icon-2 svg-icon-gray-500'
                                />
                            </button>
                            
                            
                            <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
                                {/*begin::Navigation*/}
                                <ul className='navi navi-hover' style={{listStyle:"none"}}>
                                    <li className={`navi-item mb-2 pt-2 ${selectCategory === '' ? 'text-primary':''}` } onClick={e=>setSelectCategory('')}>
                                        <span className='navi-text fw-bold' style={{cursor:"pointer"}}>All</span>
                                    </li>
                                    {
                                        categoryNames?.map((category, index) => {
                                            return (
                                                <li className={`navi-item mb-2 pt-2 ${category._id === selectCategory? 'text-primary':''}` } onClick={e=>setSelectCategory(category._id)}>
                                                    <span className='navi-text' style={{cursor:"pointer"}}>{category.name}</span>
                                                </li>
                                            )
                                        }
                                        )
                                    }
                                </ul>
                            </div>
                            <span className='input-group-text'>
                                <KTSVG
                                path='/media/icons/duotune/general/gen004.svg'
                                className='svg-icon-2 svg-icon-gray-900 '
                                />
                            </span> 
                            <form className='d-flex align-items-center' onSubmit={handleSearch}>
                                <input
                                type='text'
                                className='form-control ps-2 '
                                placeholder='Search'
                                value={searchQuery}
                                onChange={e=>setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Voucher Name</th>
                                    <th className='min-w-140px'>Parent Category</th>
                                    <th className='min-w-140px'>Actual Price</th>
                                    <th className='min-w-50px'>Status</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tests.map((test, index) => {
                                    var category_name_db = test.category_info;
                                    if(test.status === 1){
                                        var category_status_name = 'Active'
                                        var status = 'success'
                                    }else{
                                        var category_status_name = 'Inactive'  
                                        var status = 'danger' 
                                    }
                                    return (

                                    
                                <tr key={index} id={`row`+test._id}>
                                    <td>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'> */}
                                                    {test.name}
                                                {/* </a> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {test.parent_category}
                                        {/* {test.parent_category === '0' || test.parent_category === '000000000000000000000000'? 'Main Category': test.parent_category} */}
                                    </td>
                                    <td>
                                    <span className='fw-bold text-muted'>$</span> {test.price} 
                                    </td>
                                    <td className=''>
                                        <span className={`badge badge-light-`+status}>{category_status_name}</span>
                                    </td>
                                    <td className=''>
                                        <div className='d-flex flex-shrink-0'>
                                            <button onClick={() => viewDetails(test._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen028.svg'
                                                className='svg-icon-3'
                                            />
                                            </button>
                                            <button onClick={() => editTest(test._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                            </button>
                                            <button onClick={() => deleteTest(test._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                            />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                    )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
                <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                    {/* <Modal.Header closeButton >
                    <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                    </Modal.Header> */}
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>{modalTitle}</h1>
                        </div>

                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Form.Group controlId="TestName">
                                <Form.Label>Voucher Name <span className='text-danger'>*</span></Form.Label>
                                <Form.Control className='form-control-sm' type="test" placeholder="Enter Voucher Name" value={test_name} onChange={(e)=> setTestName(e.target.value)} required disabled={viewMode}/>
                            </Form.Group>
                            <Form.Group controlId="generateSlug" className='mt-5'>
                                <Form.Label>Slug <span className='text-danger'>*</span></Form.Label>
                                <Form.Control className='form-control-sm' type="text" placeholder="Enter Slug" value={slug} onChange={(e)=> setSlug(e.target.value)} required disabled={viewMode}/>
                            </Form.Group>
                            {/* generate slug button */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <button type="button" className="btn btn-link border-bottom fs-6 p-0 pt-2" onClick={generateSlug} disabled={viewMode}><small>Generate Slug</small></button>
                                </div>
                            </div>

                            {/* options for parent test */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="ParentCategory" className='mt-5'>
                                        <Form.Label>Parent Category</Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={parent_category} onChange={(e)=> setParentCategory(e.target.value)} disabled={viewMode}>
                                            <option value='000000000000000000000000'>Select Parent Category </option>
                                            {categoryNames.map((category, index) => {
                                                return (
                                                    <option key={index} value={category._id}>{category.name}</option>
                                                )
                                            })}
                                            {/* <option value="000000000000000000000000">No Parent</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option> */}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicStatus" className='mt-5'>
                                        <Form.Label>Validity </Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={validity} onChange={(e)=> setValidity(e.target.value)} disabled={viewMode}>
                                            <option value=''>Select Validity </option>
                                            <option value="1 Month">1 Month</option>
                                            <option value="6 Months">6 Months</option>
                                            <option value="1 Year">1 Year</option>
                                        </Form.Select>

                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPrice" className='mt-5'>
                                        <Form.Label> Selling Price<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control className='form-control-sm' type="number" placeholder="Enter Selling Price" value={price} onChange={(e)=> setPrice(e.target.value)} required disabled={viewMode} min="0"/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                        <Form.Label>Actual Price <small>(provided by comptia, microsoft)</small></Form.Label>
                                        <Form.Control className='form-control-sm' type="number" placeholder="Enter Actual Price" value={discountedPrice} onChange={(e)=> setDiscountedPrice(e.target.value)} disabled={viewMode} min="0"/>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicStatus" className='mt-5'>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={status} onChange={(e)=> setTestStatus(e.target.value)} disabled={viewMode}>
                                            <option value='1'>Active</option>
                                            <option value='0'>Inactive</option>
                                        </Form.Select>
                                        
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPopular" className='mt-5'>
                                        <Form.Label>Popular</Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={isPopular} onChange={(e)=> setIsPopular(e.target.value)} disabled={viewMode}>
                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                            {/* form.group for meta_title and meta_description */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicMetaTitle" className='mt-5'>
                                        <Form.Label>Meta Title</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Meta Title" value={metaTitle} onChange={(e)=> setMetaTitle(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicMetaDescription" className='mt-5'>
                                        <Form.Label>Meta Description</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Meta Description" value={metaDescription} onChange={(e)=> setMetaDescription(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>

                            <Form.Group controlId="formBasicLoginDesc" className='my-5'>
                                <Form.Label>Long Description <span className='text-danger'>*</span></Form.Label>
                                <Editor
                                    disabled={viewMode}
                                    apiKey='3djck8249ppha7ajlw5u4i4sslhhbefzwbhbhys4v3fy1t1q'
                                    onInit={(evt, editor) => editorRefDescription.current = editor}
                                    initialValue={DescriptionInitial}
                                    init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help | table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    onEditorChange={(newText) => setDescription(newText)}
                                    
                                    // onChange={(e) => setDescription(e.target.getContent())}
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicLoginDesc" className='my-5'>
                                <Form.Label>Product Schema </Form.Label>
                                <textarea
                                    disabled={viewMode}
                                    className='form-control form-control-sm'
                                    rows={5}
                                    placeholder="Enter Product Schema"
                                    value={productSchema}
                                    onChange={(e)=> setProductSchema(e.target.value)}
                                />
                            </Form.Group>


                        </Form>
                    </Modal.Body>
                        {/* modal footer */}
                        {viewMode ? '': (
                            <div className='modal-footer d-flex justify-content-center pt-4'>
                                <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                    Close
                                </button>
                                <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" onClick={handleSubmit}>
                                    {modalTitle}
                                </button>
                            </div>
                        )}
                    </Modal>
        </>
    )
}

export default Tests
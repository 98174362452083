import React,{useEffect, useState, useRef} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import { Form, Button } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import { EyeFill, Send, Funnel, Calendar } from 'react-bootstrap-icons';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import BeatLoader from "react-spinners/BeatLoader";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
// FilePond.registerPlugin(FilePondPluginFileValidateType);

const Orders = () => {
    const site_name = process.env.REACT_APP_SITE_NAME;
    const site_contact_email = process.env.REACT_APP_CONTACT_EMAIL;
    const editorRefMail = useRef(null);
    const [mailDescription, setMailDescription] = useState('');

    const [MailInitial, setMailInitial] = useState("");

    const token =  localStorage.getItem('kt-auth-react-v.api_token')
    const [offset, setOffset] = useState(1);
    const [fromDate, setFromDate] = useState(); //new Date().toISOString().slice(0, 10) '2020-01-01'
    const [toDate, setToDate] = useState();
    const [sortBy, setSortBy] = useState(-1);
    const [searchQuery, setSearchQuery] = useState('');
    const [contentLoading, setContentLoading] = useState(false);
    const [allOrders, setAllOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [ordersParPage, setOrdersParPage] = useState(30)
    const [orderDetails, setOrderDetails] = useState([]);
    const [lgshow, setShow] = useState(false);
    const [filelgshow, setFileShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('View Order Details');
    const [filemodalTitle, setFileModalTitle] = useState('Upload Result');
    const [viewMode, setViewMode] = useState(false);
    const [sendMailId, setSendMailId] = useState('');
    const [files, setFiles] = useState([])
    const [orderId, setOrderId] = useState('');
    const [mailSubject, setMailSubject] = useState('');
    const [voucher_code,  setVoucherCode] = useState('');
    const [expiration_date, setExpirationDate] = useState('');
    const [cancelReason, setCancelReason] = useState('');
    const [cancelOrderId, setCancelOrderId] = useState('');



    const [sendMailShow, setSendMailShow] = useState(false);
    const [sendMailModalTitle, setSendMailModalTitle] = useState('Send Voucher');

    const [cancelOrderShow, setCancelOrderShow] = useState(false);
    // const [cancelOrderModalTitle, setCancelOrderModalTitle] = useState('Cancel Order');

    useEffect(() => {
        getOrders();
    }, [sortBy, searchQuery, offset, fromDate, toDate])


    const getOrderData = (data, offset) => {
        return (
            data.map(order =>{
                const order_data = order.test_data;
                return (
                <tr key={order_data._id}>
                    <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                        </div>
                    </td>
                    <td>
                        {order?.order_id}
                    </td>
                    <td>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                                {order?.first_name} {order?.last_name}
                            </div>
                        </div>
                    </td>
                    <td>
                        {formatDate(order?.created_at)}
                    </td>
                    <td>
                        <Form.Group controlId="formBasicStatus" className='mt-5'>
                            <Form.Select aria-label="Default select example" className='form-select-sm' value={order?.status} onChange={(e)=> setOrderStatus(e, order?._id)}>
                                <option value='1'>Pending</option>
                                {/* <option value='2'>Processing</option> */}
                                <option value='2'>Voucher Sent</option>
                                <option value='3'>Cancelled</option>
                            </Form.Select>
                            
                        </Form.Group>
                    </td>
                    <td className='text-center fw-bold'>
                        $ {order?.amount}
                    </td>
                    <td className=''>
                        <div className='d-flex flex-shrink-0'>
                            <button onClick={() => viewDetails(order._id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='View Details'
                            >
                            <KTSVG
                                path='/media/icons/duotune/general/gen028.svg'
                                className='svg-icon-3'
                            />
                            </button>
                            {order?.voucher_sent ?
                                // <button onClick={() => ViewEmail(order._id)}
                                // className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='View Email'
                                // >
                                //     <EyeFill />
                                // </button>
                                <small className='mt-3'><b>Voucher Sent</b></small>
                                :
                                <>
                                    {order?.status == 3 ?
                                        <small className='mt-3'><b>Cancelled</b></small>

                                        : 
                                        <>
                                            {order?.status == 2 ?
                                                <small className='mt-3'><b>Voucher Sent</b></small>
                                                :
                                                <button onClick={() => SendEmailView(order._id)}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='Send Voucher'
                                                >
                                                <Send />
                                            </button>
                                            }
                                        </>
                                    }
                                </>
                            }

                            {/* <button onClick={() => uploadResultBtn(order.order_id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='Upload Result'
                            >
                            <KTSVG
                                path='/media/icons/duotune/general/gen005.svg'
                                className='svg-icon-3'
                            />
                            <Upload />
                            </button> */}
                            
                        </div>
                    </td>
                </tr>
                )
            })
        )
    }
    
    const getOrders = async () => {
        setContentLoading(true);
        
        // if (searchQuery === '' && fromDate === undefined && toDate === undefined && sortBy === -1) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/all?sort=${sortBy}&keyword=${searchQuery}&from_date=${fromDate}&to_date=${toDate}`, { headers: {"Authorization" : ` ${token}`} })
            const data = res.data;
            const order_data = data.orders;
            const slice = order_data.slice(offset - 1 , offset - 1 + parseInt(ordersParPage));
            const ordersData = getOrderData(slice);

            setAllOrders(ordersData);
            setPageCount(Math.ceil(order_data.length / ordersParPage));
            setTotalItems(order_data.length);
        // }
        // else{
        //     getOrdersBySearch();
           
        // }
        setContentLoading(false);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * ordersParPage +1);
        // console.log(newOffset);
        setOffset(newOffset);
        setOrdersParPage(ordersParPage);
    };
    const viewDetails = (order_id) => {
        try{
            axios.get(`${process.env.REACT_APP_API_URL}/orders/order-details/${order_id}`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data.orders;
                setOrderDetails(data);
                handleShow("", 'View Order Details', 'View Order Details', true);

            }
            )
        }catch(err){
            console.log(err);
        }
    }

    const handleShow = (e, title="View Order Details", button="View Order Details", viewMode=false) => {
        if(viewMode){
            setViewMode(true);
        }else{
            setViewMode(false);
        }
    
    // console.log(title, button);
        setModalTitle(title);
        setModalTitle(button);
        setShow(true);
    }
    const handleClose = () => {
        setOrderDetails([]);
        setShow(false);

    }

    const filehandleClose = () => {
        setFiles([]);

        setFileShow(false);
    }

    const formatDate = (date) => {
        // console.log(date);
        // Oct 19, 2022 9:00 AM
        const d = new Date(date);
        const year = d.getFullYear();
        const month = d.getMonth()+1;
        // month name
        const monthName = d.toLocaleString('default', { month: 'short' });

        const day = d.getDate();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();
        // hourse to 12 and add am pm
        const hours12 = hours > 12 ? hours - 12 : hours;
        const ampm = hours >= 12 ? 'PM' : 'AM';

        const minutes0 = minutes < 10 ? `0${minutes}` : minutes;
        const hours0 = hours12 < 10 ? `0${hours12}` : hours12;
        const seconds0 = seconds < 10 ? `0${seconds}` : seconds;

        const formattedDate = `${monthName} ${day}, ${year} ${hours0}:${minutes0} ${ampm}`;
        return formattedDate;
    }   

    const setOrderStatus = (e, order_id) => {
        // alert(e.target.value);
        try{
            if(e.target.value === '3'){
                setCancelOrderId(order_id);
                setCancelOrderShow(true);
            }else{
                setOrderStatusChange(e.target.value, order_id, "");
            }
        }catch(err){
            console.log(err);
        }
    }

    const setOrderStatusChange = (value, order_id, cancel_reason="") => {
        try{
            axios.patch(`${process.env.REACT_APP_API_URL}/orders/change-status`, {status: value, id: order_id, cancel_reason}, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data;
                if(data.status === true){
                    toast.success(data.message);
                    handleClose();
                    handleCancelOrderClose();
                    getOrders();
                }
            }
            )
        }
        catch(err){
            console.log(err);
        }

    }

    const cancelOrder = (e) => {
        e.preventDefault();
        setOrderStatusChange(3, cancelOrderId, cancelReason);
    }
   

    const uploadResult = async (e) => {
        e.preventDefault();
        // check if file is not pdf
        if(files[0].file.type !== 'application/pdf'){
            toast.error('Please upload a pdf file');
            return;
        }
        const formData = new FormData();
        formData.append('order_id', orderId);
        formData.append('result', files[0].file);
        try{
            axios.post(`${process.env.REACT_APP_API_URL}/orders/upload-result`, formData, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data;
                if(data.status === true){
                    toast.success(data.message);
                    filehandleClose();
                    getOrders();
                }
            }
            )
        }
        catch(err){
            console.log(err);
        }

    }

    const SendEmailView = async(order_id) => {
        try{
            axios.get(`${process.env.REACT_APP_API_URL}/orders/order-details/${order_id}`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data.orders;
                setOrderDetails(data);
                setSendMailId(data._id);
                setMailSubject(`Your Exam E-Voucher Purchase`);
            setMailInitial(`
                Dear ${data.first_name} ${data.last_name}, <br>
                Thank you for choosing Exam E-Voucher for your exam preparation needs. We're thrilled to send you your purchased voucher. You're one step closer to achieving your goals! <br><br>
                Below are the details of your purchase: <br>
                <b>E-Voucher Code:</b> ${voucher_code} <br>
                <b>Exam: </b>  <br>
                <b>Order Number:</b> ${data.order_id} <br>
                <b>Expiry Date:</b> ${expiration_date} <br>
                <b>Date of Purchase:</b> ${formatDate(data.created_at)} <br><br/>

                Please keep in mind: <br>
                <ul>
                <li>This E-Voucher is non-transferable and can only be used for the exam mentioned above.</li>
                <li>Once redeemed, it cannot be refunded or exchanged for cash.</li>
                <li>Ensure to use the E-Voucher before its expiry date to avoid any inconvenience. </li>
                <li>For any queries or assistance regarding your E-Voucher or our products, feel free to reach out to our dedicated customer support team at ${site_contact_email}. </li>
                </ul>
                Thank you once again for choosing Exam E-Voucher. We're excited to support you on your journey towards success! <br><br>
                Best regards, <br><br/>
                <b>
                Exam E-Vouchers <br>
                
                info@examevouchers.com <br>
                +1 571 599 7989 <br>
                <a href='https://www.examevouchers.com'>www.examevouchers.com</a>
                </b>
                `);


                // setMailInitial(`Dear ${data.first_name} ${data.last_name}, <br> We would like to express our sincere gratitude for purchasing a discount exam voucher from our website. We appreciate your trust and confidence in our services. <br/><br/> Your purchase details are as follows: <br/><b>Voucher Code:  </b><br/> Please note that your voucher is valid until: ${expiration_date}<br/>We hope that our voucher will provide you with an opportunity to save on your next exam too. <br/> If you have any questions or concerns, please don't hesitate to contact us at ${site_contact_email} <br/><br/>Thank you once again for choosing our services. We wish you the best of luck in your upcoming exam.<br/><br/> Best regards, <br/> <b>${site_name}</b> `);
                HandleSendMailShow("", 'Send Voucher', 'Send Voucher', true);
            }
            )
        }
        catch(err){
            console.log(err);
        }
    }
    
    const sendMailFormClose = () => {
        setMailDescription('');
        setMailSubject('');
        setSendMailId('');
        setSendMailShow(false);
    }

    const handleCancelOrderClose = () => {
        setCancelOrderId('');
        setCancelOrderShow(false);
    }

    const showCancelOrder = (e, order_id) => {
        setCancelOrderId(order_id);
        setCancelOrderShow(true);
    }

    const sendMail = async (e) => {
        e.preventDefault();
        if(mailDescription === '' || mailSubject === '' || sendMailId === ''){
            toast.error('Please fill all the fields');
            return;
        }
        const btn = document.getElementById('sendMailBtn');
        btn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp; Sending...`;
        btn.disabled = true;

        // return;

        try{
            axios.patch(`${process.env.REACT_APP_API_URL}/orders/send-voucher`, {order_id: sendMailId, subject: mailSubject, body: mailDescription}, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data;
                if(data.status === true){
                    toast.success(data.message);
                    sendMailFormClose();
                    getOrders();
                }
            }
            ).catch(err => {
                console.log(err);
                toast.error('Something went wrong');
            }
            ).finally(() => {
                btn.disabled = false;
                btn.innerHTML = 'Send Voucher';
            }
            )
            
        }
        catch(err){
            console.log(err);
        }
    }

    const HandleSendMailShow = (e, title="Send Voucher", button="Send Voucher") => {

    // console.log(title, button);
        setSendMailModalTitle(title);
        setSendMailModalTitle(button);
        setSendMailShow(true);
    }

    // const ViewEmail = async(order_id) => {
    //     try{
    //         axios.get(`${process.env.REACT_APP_API_URL}/orders/order-details/${order_id}`, { headers: {"Authorization" : ` ${token}`} })
    //         .then(res => {
    //             const data = res.data.orders;
    //             setOrderDetails(data);
                
    //         }
    //         )
    //     }
    //     catch(err){
    //         console.log(err);
    //     }
    // }

    const handleSearch = (e) => {
        e.preventDefault();
        // console.log();
        setSearchQuery(e.target[0].value);
    }

    const handleCallback = (start, end, label) => {
        setFromDate(start.format('YYYY-MM-DD'));
        setToDate(end.format('YYYY-MM-DD'));
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Orders</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Orders <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems<10?'0'+totalItems:totalItems}</span></span>
                    
                    </h3>
                    <div className='dropdown dropdown-inline'>
                        <DateRangePicker
                        onCallback={handleCallback}
                            // initialSettings={}
                        >
                            <button
                            type='button'
                            className='btn btn-sm btn-light-primary me-2'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            >
                                <Calendar size={18}
                                path='/media/icons/duotune/arrows/arr072.svg'
                                className='svg-icon-2 svg-icon-gray-500'
                                />
                            </button>
                        </DateRangePicker>
                        <button
                        type='button'
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        >
                            <Funnel size={18}
                            path='/media/icons/duotune/arrows/arr072.svg'
                            className='svg-icon-2 svg-icon-gray-500'
                            />
                        </button>
                        
                        <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
                            {/*begin::Navigation*/}
                            <ul className='navi navi-hover' style={{listStyle:"none"}}>
                                <li className={`navi-item mb-2 pt-2 ${sortBy ===1? 'text-primary':''}` } onClick={e=>setSortBy(1)}>
                                    <span className='navi-text fw-bold' style={{cursor:"pointer"}}>Ascending</span>
                                </li>
                                <li className={`navi-item ${sortBy ===-1? 'text-primary':''}` } onClick={e=>setSortBy(-1)}>
                                    <span className='navi-text fw-bold' style={{cursor:"pointer"}}>Descending</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    {/* <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                    >
                    <button
                        onClick={e=>handleShow(e, "Add Patient", "Add Patient")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        New Patient
                    </button>
                    </div> */}
                </div>
                <div className='card-header border-0 pt-2'>
                    <div className='card-toolbar'>
                        <div className='input-group input-group-sm input-group-solid w-230px border-zk'>
                            <span className='input-group-text'>
                                <KTSVG
                                path='/media/icons/duotune/general/gen004.svg'
                                className='svg-icon-2 svg-icon-gray-900'
                                />
                            </span> 
                            <form className='d-flex align-items-center' onSubmit={handleSearch}>
                                <input
                                type='text'
                                className='form-control ps-2 '
                                placeholder='Search'
                                value={searchQuery}
                                onChange={e=>setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Order Number</th>
                                    <th className='min-w-150px'>Customer Name</th>
                                    <th className='min-w-150px'>Order Date</th>
                                    <th className='min-w-150px'>Order Status</th>
                                    <th className='min-w-150px text-center'>Total Paid</th>
                                    {/* <th className='min-w-150px'>Payment Id</th> */}
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allOrders}
                            </tbody>
                        </table>

                        
                        <div className='text-center'>
                            {/* <span>{totalItems}</span> */}
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                        </div>
                    </div>
                </div>
            </div>

            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
            <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                {/* <Modal.Header closeButton >
                <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                </Modal.Header> */}
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                    <div className='text-center mb-13'>
                        <h1 className='mb-3'>{modalTitle}</h1>
                    </div>

                    <Form>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="TestName">
                                    <Form.Label>Order Number</Form.Label>: 
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.order_id} disabled={viewMode}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="TestName">
                                    <Form.Label>Order Date</Form.Label>:
                                    <Form.Control className='form-control-sm' type="text" value={formatDate(orderDetails?.created_at)} disabled={viewMode}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div className='mt-5'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="TestName">
                                        <Form.Label>First Name</Form.Label>: 
                                        <Form.Control className='form-control-sm' type="text" value={orderDetails?.first_name} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="TestName">
                                        <Form.Label>Last Name</Form.Label>: 
                                        <Form.Control className='form-control-sm' type="text" value={orderDetails?.last_name} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>

                        {/* options for parent test */}
                        
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicPrice" className='mt-5'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.email} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.phone} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicPrice" className='mt-5'>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.city} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.state} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicPrice" className='mt-5'>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.country} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.zip} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicStatus" className='mt-5'>
                                    <Form.Label>Payment Id</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails?.payment_id} disabled={viewMode}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                            <Form.Group controlId="formBasicStatus" className='mt-5'>
                                    <Form.Label>Payment Method</Form.Label>
                                    <Form.Select aria-label="Default select example" className='form-select-sm' disabled value={orderDetails?.payment_method}>
                                        <option value='1'>Square</option>
                                        {/* <option value='2'>Processing</option> */}
                                        <option value='2'>Paypal</option>
                                        <option value='3'>Other</option>
                                    </Form.Select>
                                    
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicStatus" className='mt-5'>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" className='form-select-sm' disabled value={orderDetails?.status} onChange={(e)=> setOrderStatus(e, orderDetails?._id)}>
                                        <option value='1'>Pending</option>
                                        {/* <option value='2'>Processing</option> */}
                                        <option value='2'>Voucher Sent</option>
                                        <option value='3'>Cancelled</option>
                                    </Form.Select>
                                    
                                </Form.Group>
                                
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicStatus" className='mt-5'>
                                    <Form.Label>Source</Form.Label>
                                    <Form.Select aria-label="Default select example" className='form-select-sm' disabled value={orderDetails?.source}>
                                        <option value='1'>Website</option>
                                        {/* <option value='2'>Processing</option> */}
                                        <option value='2'>Google Ad</option>
                                        <option value='3'>Other</option>
                                    </Form.Select>
                                    
                                </Form.Group>
                                
                            </div>
                        </div>
                        {orderDetails?.status == 3 && <div className='bg-light p-3 mt-3'><span className='text-danger fw-bold'>Reason for cancellation: </span> {orderDetails?.cancel_reason}</div>} 

                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-5'>
                            <thead>
                                <tr className='fw-bolder text-muted bg-light'>
                                    {/* <th className='border-0'></th> */}
                                    <th className='min-w-150px ps-2'>Test Name</th>
                                    <th className='min-w-150px'>Price</th>
                                    <th className='min-w-150px'>Quantity</th>
                                    <th className='min-w-150px'>Total</th>
                                </tr>
                            </thead>
                            <tbody className='border'>
                                {orderDetails?.test_data?.map((item, index) => {
                                    // console.log(item);
                                    return (
                                        <tr key={index}>
                                            {/* <td ></td> */}
                                            <td className='ps-2'>{item?.name}</td>
                                            <td>$ {item?.price}</td>
                                            <td>{item?.quantity}</td>
                                            <td>$ {item?.price * item?.quantity}</td>
                                        </tr>
                                    )
                                }
                                )}
                                
                            </tbody>
                            <tfoot>
                                {orderDetails?.payable && 
                                <tr className='fw-bolder border-0'>
                                    <td colSpan='2'></td>
                                    <td colSpan=''>Total</td>
                                    <td>$ {orderDetails?.payable}</td>
                                </tr>
                                }
                                {/* discount */}
                                {orderDetails?.promo_discount > 0 &&
                                <tr className='fw-bolder border-0'>
                                    <td colSpan='2'></td>
                                    <td colSpan=''>Discount</td>
                                    <td>-$ {orderDetails?.promo_discount}</td>
                                </tr>
                                }
                                {/* discount */}
                                <tr className='fw-bolder border-0'>
                                    <td colSpan='2'></td>
                                    <td colSpan=''>Total Payable</td>
                                    <td>$ {orderDetails?.amount}</td>
                                </tr>
                            </tfoot>
                        </table>
                        {orderDetails?.voucher_sent && 
                        <>
                        <hr></hr>

                        <h4 className='text-center'>Email Info </h4>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='text-capitalize text-work-sans fs-np-14 py-1' style={{fontWeight:"500"}}>
                                Subject:
                                </div>
                            </div>
                            <div className='col-md-12  bg-light py-3'>
                                <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    {orderDetails?.voucher_email_subject}
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <div className='text-capitalize text-work-sans fs-np-14 py-1' style={{fontWeight:"500"}}>
                                Mail:
                                </div>
                            </div>
                            <div className='col-md-12  bg-light py-3'>
                                <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3'>
                                    <div dangerouslySetInnerHTML={{__html: orderDetails?.voucher_email_body}}></div>
                                </div>
                            </div>
                        </div>
                        </>
                        }

                    </Form>
                </Modal.Body>
                    {/* modal footer */}
                    {viewMode ? '': (
                        <div className='modal-footer d-flex justify-content-center pt-4'>
                            <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                Close
                            </button>
                            <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" >
                                {modalTitle}
                            </button>
                        </div>
                    )}
            </Modal>


            <Modal onHide={sendMailFormClose} className='modal fade' size="lg" show={sendMailShow}>
                {/* <Modal.Header closeButton >
                <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                </Modal.Header> */}
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={sendMailFormClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                    <div className='text-center mb-13'>
                        <h1 className='mb-3'>{sendMailModalTitle}</h1>
                    </div>
                    <Form onSubmit={sendMail} >
                        <div className='mt-5'>
                            <div className='row'>

                            <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    Order Number
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                    {orderDetails?.order_id} 
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    Order Date
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                    {formatDate(orderDetails?.created_at)}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    Full Name
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                    {orderDetails?.first_name} {orderDetails?.last_name}
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    Email
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                    {orderDetails?.email}
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    Phone Number
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                     {orderDetails?.phone}
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                   City
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                     {orderDetails?.city}
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    State
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                     {orderDetails?.state}
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    Country
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                     {orderDetails?.country}
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"600"}}>
                                    Zip Code
                                    </div>
                                </div>
                                <div className='col-md-8 text-break'>
                                    <div className='text-capitalize text-work-sans fs-np-14 py-1 mb-3' style={{fontWeight:"500"}}>
                                     {orderDetails?.zip}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* options for parent test */}
                        
                        
                        <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                            <Form.Label>Subject</Form.Label>
                            <Form.Control className='form-control-sm' type="text" value={mailSubject} required />
                        </Form.Group>

                        <Form.Group controlId="formBasicLoginDesc" className='my-5'>
                        <Form.Label>Mail Body</Form.Label>

                        <Editor
                            apiKey='3djck8249ppha7ajlw5u4i4sslhhbefzwbhbhys4v3fy1t1q'
                            onInit={(evt, editor) => editorRefMail.current = editor}
                            initialValue={MailInitial}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help | table',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                            onEditorChange={(newText) => setMailDescription(newText)}
                            
                            // onChange={(e) => setMailDescription(e.target.getContent())}
                        />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                    {/* modal footer */}
                        <div className='modal-footer d-flex justify-content-center pt-4'>
                            <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' data-bs-dismiss='modal' onClick={sendMailFormClose}>
                                Close
                            </button>
                            <button type='button' className='btn btn-sm btn-flex btn-primary' id="sendMailBtn" onClick={sendMail}>
                                {sendMailModalTitle}
                            </button>
                        </div>
            </Modal>
            {/* Modal for cancel order reason */}
            <Modal show={cancelOrderShow} onHide={handleCancelOrderClose} className='modal fade'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize text-work-sans fw-bold fs-3'>
                        Cancel Order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={cancelOrder}>
                        <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                            <Form.Label>Reason</Form.Label>
                            <Form.Control className='form-control-sm' type="text" value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} required />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                            
                {/* modal footer */}
                <div className='modal-footer d-flex justify-content-center pt-4'>
                    <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleCancelOrderClose}>
                        Close
                    </button>
                    <button type='button' className='btn btn-sm btn-flex btn-primary' onClick={cancelOrder}>
                        Cancel Order
                    </button>
                </div>
            </Modal>

        </>
    )
}

export default Orders
